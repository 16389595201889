import { useState, useEffect } from "react";

interface UserData {
  id: number;
  name: string;
  username: string;
  email: string;
  dark_mode: boolean;
  color_blind_mode: boolean;
  created_at: string;
  updated_at: string;
  [key: string]: any; // For additional fields
}

interface StorageData {
  anonymousId?: string;
  user?: string;
  lastAuthCheck?: string;
  parsedUser?: UserData;
  [key: string]: any; // For dynamic keys
}

interface GameStats {
  totalGames: number;
  gamesFailed: number;
  currentStreak: number;
  bestStreak: number;
  successRate: number;
  winDistribution: number[];
}

const LEVELS = [
  "easy",
  "easy-practice",
  "hard",
  "hard-practice",
  "killer",
  "killer-practice",
  "normal",
  "normal-practice",
];

function formatDateTime(timestamp: string | undefined): string {
  if (!timestamp) return "Not set";
  return new Date(parseInt(timestamp)).toLocaleString();
}

function generateRandomWinDistribution(totalWins: number): number[] {
  const distribution = new Array(6).fill(0);
  let remainingWins = totalWins;

  // Distribute wins across positions with some randomization
  for (let i = 0; i < 5 && remainingWins > 0; i++) {
    const maxForThisPosition = Math.min(
      remainingWins,
      Math.floor(totalWins * 0.4),
    );
    const wins = Math.floor(Math.random() * maxForThisPosition);
    distribution[i] = wins;
    remainingWins -= wins;
  }

  // Put any remaining wins in the last position
  distribution[5] = remainingWins;

  return distribution;
}

export default function StorageManager() {
  const [selectedLevel, setSelectedLevel] = useState("normal");
  const [totalGames, setTotalGames] = useState(0);
  const [gamesFailed, setGamesFailed] = useState(0);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [bestStreak, setBestStreak] = useState(0);
  const [storageData, setStorageData] = useState<StorageData>({});
  const [isSubdomain, setIsSubdomain] = useState(false);

  const handleReset = () => {
    if (!isSubdomain) {
      alert("Resetting is only allowed on subdomains");
      return;
    }

    if (
      window.confirm(
        "Are you sure you want to reset all data? This cannot be undone.",
      )
    ) {
      // Clear auth related data
      localStorage.removeItem("heygg_anonymous_id");
      localStorage.removeItem("heygg_user");
      localStorage.removeItem("heygg_last_auth_check");
      localStorage.removeItem("accountOnBoard");

      // Clear sync flags for each level
      LEVELS.forEach((level) => {
        localStorage.removeItem(`mathler-historical-sync-${level}`);
      });

      // Clear game stats for each level
      LEVELS.forEach((level) => {
        localStorage.removeItem(`gameStats-${level}`);
        localStorage.removeItem(`gameState-${level}`);
      });

      // Refresh the display
      refreshStorageData();
    }
  };

  const refreshStorageData = () => {
    const data: StorageData = {};

    // Get auth related data
    data.anonymousId = localStorage.getItem("heygg_anonymous_id") || undefined;
    const userStr = localStorage.getItem("heygg_user");
    data.user = userStr || undefined;
    if (userStr) {
      try {
        data.parsedUser = JSON.parse(userStr);
      } catch (e) {
        console.error("Failed to parse user data:", e);
      }
    }
    data.lastAuthCheck =
      localStorage.getItem("heygg_last_auth_check") || undefined;

    // Get sync flags for each level
    LEVELS.forEach((level) => {
      data[`mathler-historical-sync-${level}`] = localStorage.getItem(
        `mathler-historical-sync-${level}`,
      );
    });

    // Get game stats for each level
    LEVELS.forEach((level) => {
      const stats = localStorage.getItem(`gameStats-${level}`);
      data[`gameStats-${level}`] = stats ? JSON.parse(stats) : null;
    });

    setStorageData(data);
  };

  useEffect(() => {
    refreshStorageData();

    // Check if we're not on production
    const hostname = window.location.hostname;
    setIsSubdomain(hostname !== "mathler.com");
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isSubdomain) {
      alert("Editing is only allowed on subdomains");
      return;
    }

    const totalWins = totalGames - gamesFailed;
    if (totalWins < 0) {
      alert("Games failed cannot be greater than total games");
      return;
    }

    const winDistribution = generateRandomWinDistribution(totalWins);
    const successRate = Math.round((totalWins / Math.max(totalGames, 1)) * 100);

    const stats: GameStats = {
      totalGames,
      gamesFailed,
      currentStreak,
      bestStreak,
      successRate,
      winDistribution,
    };

    localStorage.setItem(`gameStats-${selectedLevel}`, JSON.stringify(stats));
    refreshStorageData();
  };

  return (
    <div className="p-8 max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Mathler Storage Manager</h1>
        {isSubdomain && (
          <button
            onClick={handleReset}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Reset All Data
          </button>
        )}
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Auth Data</h2>
        <div className="space-y-2">
          <p>
            <strong>Anonymous ID:</strong>{" "}
            {storageData.anonymousId || "Not set"}
          </p>
          <div>
            <strong>User:</strong>
            {storageData.parsedUser ? (
              <div className="ml-4 mt-2">
                <p>ID: {storageData.parsedUser.id}</p>
                <p>Name: {storageData.parsedUser.name}</p>
                <p>Username: {storageData.parsedUser.username}</p>
                <p>Email: {storageData.parsedUser.email}</p>
                <p>
                  Dark Mode: {storageData.parsedUser.dark_mode ? "Yes" : "No"}
                </p>
                <p>
                  Color Blind Mode:{" "}
                  {storageData.parsedUser.color_blind_mode ? "Yes" : "No"}
                </p>
                <p>
                  Created:{" "}
                  {new Date(storageData.parsedUser.created_at).toLocaleString()}
                </p>
                <p>
                  Updated:{" "}
                  {new Date(storageData.parsedUser.updated_at).toLocaleString()}
                </p>
              </div>
            ) : (
              " Not set"
            )}
          </div>
          <p>
            <strong>Last Auth Check:</strong>{" "}
            {formatDateTime(storageData.lastAuthCheck)}
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Sync Status</h2>
        <div className="grid grid-cols-2 gap-4">
          {LEVELS.map((level) => (
            <div key={`sync-${level}`}>
              <strong>{level}:</strong>{" "}
              {storageData[`mathler-historical-sync-${level}`] || "Not synced"}
            </div>
          ))}
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Game Stats</h2>
        <div className="grid grid-cols-2 gap-4">
          {LEVELS.map((level) => (
            <div key={`stats-${level}`} className="p-4 bg-gray-100 rounded">
              <h3 className="font-semibold mb-2">{level}</h3>
              <pre className="whitespace-pre-wrap text-sm">
                {JSON.stringify(storageData[`gameStats-${level}`], null, 2)}
              </pre>
            </div>
          ))}
        </div>
      </div>

      {isSubdomain && (
        <form onSubmit={handleSubmit} className="space-y-4">
          <h2 className="text-xl font-semibold">Edit Game Stats</h2>

          <div>
            <label className="block mb-2">Level:</label>
            <select
              value={selectedLevel}
              onChange={(e) => setSelectedLevel(e.target.value)}
              className="w-full p-2 border rounded"
            >
              {LEVELS.map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block mb-2">Total Games:</label>
              <input
                type="number"
                value={totalGames}
                onChange={(e) => setTotalGames(Number(e.target.value))}
                className="w-full p-2 border rounded"
                min="0"
              />
            </div>

            <div>
              <label className="block mb-2">Games Failed:</label>
              <input
                type="number"
                value={gamesFailed}
                onChange={(e) => setGamesFailed(Number(e.target.value))}
                className="w-full p-2 border rounded"
                min="0"
              />
            </div>

            <div>
              <label className="block mb-2">Current Streak:</label>
              <input
                type="number"
                value={currentStreak}
                onChange={(e) => setCurrentStreak(Number(e.target.value))}
                className="w-full p-2 border rounded"
                min="0"
              />
            </div>

            <div>
              <label className="block mb-2">Best Streak:</label>
              <input
                type="number"
                value={bestStreak}
                onChange={(e) => setBestStreak(Number(e.target.value))}
                className="w-full p-2 border rounded"
                min="0"
              />
            </div>
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Update Stats
          </button>
        </form>
      )}
    </div>
  );
}
