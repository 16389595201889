import { useEffect, useState } from "react";

interface DebugData {
  general: {
    transferCompleted: string | null;
    playedbefore: string | null;
    colorblindmode: string | null;
    darkmode: string | null;
    accountOnBoard: string | null;
  };
  stats: Record<string, any>;
  states: Record<string, any>;
  auth: {
    anonymousId: string | null;
    lastAuthCheck: string | null;
    user: any;
  };
}

const parseJson = (str: string | null): any => {
  if (!str) return null;
  try {
    return JSON.parse(str);
  } catch (e) {
    console.error("Failed to parse JSON data:", e);
    return null;
  }
};

export default function Debug() {
  const [status, setStatus] = useState("Gathering data...");

  useEffect(() => {
    const debugData: DebugData = {
      general: {
        transferCompleted: localStorage.getItem("transferCompleted"),
        playedbefore: localStorage.getItem("playedbefore"),
        colorblindmode: localStorage.getItem("colorblindmode"),
        darkmode: localStorage.getItem("darkmode"),
        accountOnBoard: localStorage.getItem("accountOnBoard"),
      },
      stats: {},
      states: {},
      auth: {
        anonymousId: localStorage.getItem("heygg_anonymous_id"),
        lastAuthCheck: localStorage.getItem("heygg_last_auth_check"),
        user: parseJson(localStorage.getItem("heygg_user")),
      },
    };

    // Collect stats and states for all game modes
    ["normal", "easy", "hard", "killer"].forEach((level) => {
      // Regular game
      debugData.stats[level] = parseJson(
        localStorage.getItem(`gameStats-${level}`),
      );
      debugData.states[level] = parseJson(
        localStorage.getItem(`gameState-${level}`),
      );

      // Practice mode
      debugData.stats[`${level}-practice`] = parseJson(
        localStorage.getItem(`gameStats-${level}-practice`),
      );
      debugData.states[`${level}-practice`] = parseJson(
        localStorage.getItem(`gameState-${level}-practice`),
      );
    });

    // Post data to debug endpoint
    fetch("https://play.hey.gg/api/debug/mathler", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(debugData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setStatus("Debug data sent successfully");
      })
      .catch((error) => {
        console.error("Error:", error);
        setStatus("Error sending debug data");
      });
  }, []);

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Mathler Debug</h1>
      <p className="text-lg">{status}</p>
    </div>
  );
}
