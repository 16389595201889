import useDarkMode from "../hooks/useDarkMode";
import { Cell } from "./grid/Cell";
import { Keyboard } from "./keyboard/Keyboard";

export default function HowToPlay() {
  const isDarkMode = useDarkMode();

  return (
    <div className="flex w-full max-w-full overflow-hidden mx-auto items-center mb-2">
      <div
        className={`w-full max-w-[600px] mx-auto px-4 mb-2 ${
          isDarkMode ? "bg-[#2D2928]" : "bg-[#F4F7FA]"
        } instructions text-center`}
      >
        <h2>How to play Mathler</h2>
        <p>
          Mathler is a math-based game inspired by Wordle. It requires you to
          use the operations +, -, *, and / as well as the digits 0-9 to create
          an equation that equals the answer given.
        </p>
        <p>Unlike Wordle, you get the answer in advance.</p>
        <h3>Try to find the hidden calculation in 6 guesses!</h3>
        <p>
          After each guess, the color of the tiles will change to show how close
          you are to the solution.
        </p>
        <ul>
          <li>Green are in the correct place.</li>
          <li>Orange are in the solution, but in a different place.</li>
          <li>Gray are not in the solution.</li>
        </ul>
        <div className="flex my-3 justify-center">
          <Cell value="5" status="correct" />
          <Cell value="0" status="present" />
          <Cell value="/" status="correct" />
          <Cell value="5" status="absent" />
          <Cell value="-" status="absent" />
          <Cell value="2" status="correct" />
        </div>
        <h3>Additional rules:</h3>
        <ul>
          <li>Numbers and operators can appear multiple times.</li>
          <li>Calculate / or * before - or + (order of operations).</li>
          <li>
            Commutative solutions are accepted, for example 20+7+3 and 3+7+20.
          </li>
          <li>
            Commutative solutions will be automatically rearranged to the exact
            solution
          </li>
        </ul>
        <h3 className="center">An example of a winning game of 83</h3>
        <div className="flex justify-center my-1">
          <Cell value="9" status="correct" />
          <Cell value="*" status="absent" />
          <Cell value="9" status="absent" />
          <Cell value="+" status="absent" />
          <Cell value="2" status="absent" />
        </div>
        <div className="flex justify-center my-1">
          <Cell value="9" status="correct" />
          <Cell value="9" status="absent" />
          <Cell value="-" status="correct" />
          <Cell value="1" status="correct" />
          <Cell value="6" status="absent" />
        </div>
        <div className="flex justify-center my-1">
          <Cell value="9" status="correct" />
          <Cell value="8" status="absent" />
          <Cell value="-" status="correct" />
          <Cell value="1" status="correct" />
          <Cell value="5" status="absent" />
        </div>
        <div className="flex justify-center my-1">
          <Cell value="9" status="correct" />
          <Cell value="7" status="absent" />
          <Cell value="-" status="correct" />
          <Cell value="1" status="correct" />
          <Cell value="4" status="absent" />
        </div>
        <div className="flex justify-center my-1">
          <Cell value="9" status="correct" />
          <Cell value="3" status="correct" />
          <Cell value="-" status="correct" />
          <Cell value="1" status="correct" />
          <Cell value="0" status="correct" />
        </div>
        <div className="flex justify-center my-1 mb-4">
          <Cell value="" />
          <Cell value="" />
          <Cell value="" />
          <Cell value="" />
          <Cell value="" />
        </div>
        <div className="flex justify-center w-full overflow-hidden">
          <Keyboard
            guesses={[
              ["9", "*", "9", "+", "2"],
              ["9", "9", "-", "1", "6"],
              ["9", "8", "-", "1", "5"],
              ["9", "7", "-", "1", "4"],
              ["9", "3", "-", "1", "0"],
            ]}
            solution="93-10"
            level={"normal"}
            onChar={() => {}}
            onDelete={() => {}}
            onEnter={() => {}}
          />
        </div>
      </div>
    </div>
  );
}
