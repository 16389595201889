import { useState } from "react";
import { BaseModal } from "./BaseModal";
import { useEffectAfterMigration } from "../../hooks/useEffectAfterMigration";

//DD-MM-YYYY
export const updateKey = "mathler-updates-01-11-2024";

export const NewUpdateModal = ({
  openSimpleInfoModal,
}: {
  openSimpleInfoModal: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasPlayedBefore = localStorage.getItem("playedbefore"); //Played before Normal level

  useEffectAfterMigration(() => {
    const hasSeenUpdates = localStorage.getItem(updateKey);

    if (hasSeenUpdates || !hasPlayedBefore) return;

    setIsOpen(true);
    localStorage.setItem(updateKey, "true");
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <BaseModal
      title="New Mathler Updates"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className="flex flex-col text-base font-normal gap-y-4">
        <p>
          Access all Mathler levels more easily with our new tabbed navigation.
        </p>
        <img src="updates.png" alt="Navbar updates" />
        <p className="font-bold text-lg">Additional updates</p>
        <ul className="list-disc pl-4">
          <li>Play a practice round across all Mathler levels.</li>
          <li>Simplified the game interface to highlight the solution.</li>
        </ul>
      </div>
      <div className="flex flex-row gap-4 pt-4">
        <button
          type="button"
          className="flex p-4 justify-center items-center flex-grow border border-black rounded font-extrabold"
          onClick={() => {
            openSimpleInfoModal();
            handleClose();
          }}
          onTouchStart={() => {
            openSimpleInfoModal();
            handleClose();
          }}
        >
          How to Play?
        </button>

        <button
          className="flex p-4 justify-center items-center flex-grow border border-black rounded font-extrabold bg-black text-white"
          onClick={handleClose}
          onTouchStart={handleClose}
        >
          Play Now
        </button>
      </div>
    </BaseModal>
  );
};
