import { BaseModal } from "./BaseModal";
import { Level } from "../../utils/isValidLevel";
import { useState } from "react";
import { getWordOfDay } from "../../lib/words";
import capitalize from "../../utils/capitalize";
import { useEffectAfterMigration } from "../../hooks/useEffectAfterMigration";
import { StatBar } from "../stats/StatBar";
import { Histogram } from "../stats/Histogram";
import { GameStats } from "../../lib/localStorage";
import ShareButton from "../buttons/ShareButton";
import PracticeButton from "../buttons/PracticeButton";

const levels = ["easy", "normal", "hard", "killer"];

export default function WinModal({
  level,
  isOpen,
  handleClose,
  gameStats,
  isGameWon,
  isPracticeGame,
  handleShare,
  guesses,
  isGameLost,
  solutionIndex,
  solution,
  isLogin,
}: {
  level: Level;
  solution: string;
  isOpen: boolean;
  handleClose: () => void;
  gameStats: GameStats;
  isGameWon: boolean;
  isPracticeGame: boolean;
  handleShare: () => void;
  guesses: string[][];
  isGameLost: boolean;
  solutionIndex: number;
  isLogin: boolean;
}) {
  const title = !isGameLost ? "Hey, Good Game!" : "Almost had it!";

  const redirect_uri = window.location.href;
  const anonymous = localStorage.getItem("heygg_anonymous_id");

  return (
    <BaseModal title={title} isOpen={isOpen} handleClose={handleClose as any}>
      {!isLogin ? (
        <div className="flex justify-center items-center p-2 gap-2 self-stretch rounded-full bg-[#150C09] text-white my-4">
          NEW! Save your stats.
          <a
            className="text-[#91D67D] underline"
            href={`https://play.hey.gg/register?redirect_uri=${encodeURIComponent(redirect_uri)}${anonymous ? "&anonymous_id=" + encodeURIComponent(anonymous) : ""}`}
          >
            Sign up now
          </a>
        </div>
      ) : (
        <></>
      )}
      <StatBar gameStats={gameStats} />
      {gameStats?.totalGames > 0 && (
        <>
          <h4 className="text-lg leading-6 font-bold">Guess Distribution</h4>
          <Histogram
            gameStats={gameStats}
            guesses={guesses}
            isGameWon={isGameWon}
          />
        </>
      )}

      <div className="flex flex-col">
        <TryNextLevel level={level} isPracticeGame={isPracticeGame} />
        <PracticeButton
          isPractice={isPracticeGame}
          isGameLost={isGameLost}
          level={level}
        />
        <ShareButton
          guesses={guesses}
          isGameLost={isGameLost}
          solutionIndex={solutionIndex}
          solution={solution}
          handleShare={handleShare}
          isGameWon={isGameWon}
          isPracticeGame={isPracticeGame}
          level={level}
        />
      </div>
    </BaseModal>
  );
}

function TryNextLevel({
  level,
  isPracticeGame,
}: {
  level: Level;
  isPracticeGame: boolean;
}) {
  const [nextLevelToTry, setNextLevelToTry] = useState<string | null>(null);

  useEffectAfterMigration(() => {
    const startIndex = levels.indexOf(level);
    const arrayOfLevels = [
      ...levels.slice(startIndex),
      ...levels.slice(0, startIndex),
    ];

    let lookUpFrom = 0;
    let validNextLevel = false;
    let nextLevel = arrayOfLevels[lookUpFrom];

    while (validNextLevel === false && lookUpFrom < arrayOfLevels.length) {
      const gameState = localStorage.getItem(`gameState-${nextLevel}`);

      if (gameState) {
        const gameStateObj = JSON.parse(gameState);

        if (gameStateObj?.solution) {
          const { solution } = getWordOfDay(nextLevel as Level);

          if (solution !== gameStateObj.solution) {
            validNextLevel = true;
            setNextLevelToTry(nextLevel);
            return;
          }
        }
      } else {
        validNextLevel = true;
        setNextLevelToTry(nextLevel);
        return;
      }

      nextLevel = arrayOfLevels[++lookUpFrom];
    }
  }, [level]);

  if (!nextLevelToTry && isPracticeGame) return <></>;
  return (
    <a
      type="button"
      className="align-center w-full py-4 rounded-md border border-1 border-black  text-center font-sans text-lg font-extrabold mb-2"
      href={
        nextLevelToTry
          ? `${window.location.origin}/${nextLevelToTry}`
          : `${window.location.origin}/${level}/?practice=true`
      }
    >
      {nextLevelToTry
        ? `Try ${capitalize(nextLevelToTry)} Mathler`
        : "Try a Practice Game"}
    </a>
  );
}
