import { useEffect } from "react";

export function useEffectAfterMigration(
  callback: () => void,
  dependencies: any[]
) {
  useEffect(() => {
    const completed = localStorage.getItem("transferCompleted");
    if (completed) {
      callback();
      return;
    }

    let counter = 0;
    const interval = setInterval(() => {
      const completed = localStorage.getItem("transferCompleted");
      if (completed) {
        callback();
        clearInterval(interval);
      } else {
        counter++;
        if (counter >= 4) {
          callback();
          clearInterval(interval);
        }
      }
    }, 500);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
