import { StatBar } from "../stats/StatBar";
import { Histogram } from "../stats/Histogram";
import { GameStats } from "../../lib/localStorage";
import { BaseModal } from "./BaseModal";
import { defaultStats } from "../../lib/stats";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  gameStats: GameStats;
  isGameWon: boolean;
  guesses: string[][];
};

export const StatsModal = ({
  isOpen,
  handleClose,
  gameStats,
  guesses,
  isGameWon,
}: Props) => {
  const title = "Stats";

  if (!gameStats?.totalGames || gameStats?.totalGames <= 0) {
    return (
      <BaseModal title={title} isOpen={isOpen} handleClose={handleClose}>
        <StatBar gameStats={defaultStats} />
      </BaseModal>
    );
  }
  return (
    <BaseModal title={title} isOpen={isOpen} handleClose={handleClose}>
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-bold">Guess Distribution</h4>
      <Histogram
        gameStats={gameStats}
        guesses={guesses}
        isGameWon={isGameWon}
      />
    </BaseModal>
  );
};
