import { getGuessStatuses } from "./statuses";
import { CONFIG } from "../constants/config";
import { Level } from "../utils/isValidLevel";

export const shareStatus = (
  guesses: string[][],
  lost: boolean,
  solutionIndex: number,
  solution: string,
  level: Level
) => {
  const levelPlayed = level === "normal" ? "" : `${level} `;

  if (navigator.share === undefined) {
    navigator.clipboard.writeText(
      levelPlayed +
        "mathler  " +
        (solutionIndex + 1) +
        " " +
        `${lost ? "X" : guesses.length}` +
        "/" +
        CONFIG.tries.toString() +
        "\n\n" +
        generateEmojiGrid(guesses, solution, level) +
        "\n\n" +
        "mathler.com"
    );
  } else {
    navigator.clipboard.writeText(
      levelPlayed +
        "mathler " +
        (solutionIndex + 1) +
        " " +
        `${lost ? "X" : guesses.length}` +
        "/" +
        CONFIG.tries.toString() +
        "\n\n" +
        generateEmojiGrid(guesses, solution, level) +
        "\n\n" +
        "mathler.com"
    );

    navigator.share({
      text:
        levelPlayed +
        "mathler  " +
        (solutionIndex + 1) +
        " " +
        `${lost ? "X" : guesses.length}` +
        "/" +
        CONFIG.tries.toString() +
        "\n\n" +
        generateEmojiGrid(guesses, solution, level) +
        "\n\n" +
        "mathler.com",
    });
  }
};

export const generateEmojiGrid = (
  guesses: string[][],
  solution: string,
  level: Level
) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess, solution);
      return guess
        .map((_letter, i) => {
          switch (status[i]) {
            case "correct":
              return "🟩";
            case "present":
              return level !== "killer" ? "🟨" : "⬜";
            default:
              return "⬜";
          }
        })
        .join("");
    })
    .join("\n");
};
