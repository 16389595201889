import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { BaseModal } from "./BaseModal";

import slide1 from "../../images/slide-01.png";
import slide2 from "../../images/slide-02.png";
import slide3 from "../../images/slide-03.png";

import "swiper/css";
import "swiper/css/pagination";

import "./swiper-custom.css";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { updateKey } from "./NewUpdateModal";
import { useEffectAfterMigration } from "../../hooks/useEffectAfterMigration";

export default function ModalWithSlice({
  isUserLoggedIn,
  userLoading,
}: {
  isUserLoggedIn: boolean;
  userLoading: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffectAfterMigration(() => {
    let updatedKey = Number.parseInt(localStorage.getItem(updateKey) ?? "0");

    if (Number.isNaN(updatedKey)) {
      updatedKey = 0;
    }

    if (updatedKey >= 3 || isUserLoggedIn || userLoading) {
      isOpen && setIsOpen(false);
      return;
    }

    setIsOpen(true);

    localStorage.setItem(updateKey, (updatedKey + 1).toString());

    localStorage.setItem("playedbefore", "played");
  }, [setIsOpen, isUserLoggedIn, userLoading]);

  return (
    <BaseModal
      title="Mathler accounts are here!"
      isOpen={isOpen}
      handleClose={() => {
        setIsOpen(false);
      }}
      isNew={true}
    >
      <Swiper
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        pagination={{ enabled: true }}
        navigation={true}
        modules={[Pagination, Autoplay, Navigation]}
      >
        <SwiperSlide>
          <img
            src={slide1}
            className="rounded"
            alt="Slide One Mathler account"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={slide2}
            className="rounded"
            alt="Slide Two Mathler account"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={slide3}
            className="rounded"
            alt="Slide Three Mathler account"
          />
        </SwiperSlide>
      </Swiper>
      <ul className="list-disc pl-4 mt-3">
        <li>Save your stats across devices</li>
        <li>View your past game history</li>
        <li>Join our new Discord Community</li>
        <li>Keep your playing streaks alive</li>
      </ul>
      <button
        type="button"
        className="rounded-md border-2 border-[#150C09] bg-[#5EC26A] w-full h-[50px] mx-auto text-[#071E12] text-center text-sm font-extrabold shadow-custom-small-black mt-4
  hover:shadow-[5px_5px_0px_#150c09] hover:-translate-x-1 hover:-translate-y-1 active:translate-x-1 active:translate-y-1 active:shadow-none"
        onClick={() => {
          window.location.href = `https://play.hey.gg/register?return_to=${encodeURIComponent(window.location.href)}`;
        }}
      >
        GET STARTED
      </button>
    </BaseModal>
  );
}
