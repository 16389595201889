import { useEffect } from "react";
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";

const STATE_MACHINE = "State Machine 1";

export default function BurgerRive({ open }: { open: boolean }) {
  const { rive, RiveComponent } = useRive({
    src: "/burger_v2.riv",
    stateMachines: STATE_MACHINE,
    artboard: "Artboard",
    autoplay: true,
    isTouchScrollEnabled: true,
  });

  const onOpen = useStateMachineInput(rive, STATE_MACHINE, "Open");

  useEffect(() => {
    if (onOpen !== null && onOpen.value !== open) {
      onOpen.value = open;
    }
  }, [open, onOpen]);

  return <RiveComponent />;
}
