export default function MathlerIcon() {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_13464_14816)">
        <g clip-path="url(#clip0_13464_14816)">
          <rect width="40" height="40" fill="white" />
          <path d="M38.333 20H19.9997V1.66667H38.333V20Z" fill="#FFED48" />
          <path d="M20 38.333H1.66667V19.9997H20V38.333Z" fill="#91D67D" />
          <path d="M20 0L20 40" stroke="#150C09" strokeWidth="2.5" />
          <path d="M0 20L40 20" stroke="#150C09" strokeWidth="2.5" />
          <path
            d="M6.70191 13.8179V11.7724L10.6034 5.63604H12.4595V8.36331H11.3989L9.29661 11.6966V11.7724H15.0542V13.8179H6.70191ZM11.4178 15.333V13.1929L11.4746 12.3027V5.63604H13.9368V15.333H11.4178Z"
            fill="#150C09"
          />
          <path
            d="M28.9379 14.8974V7.88983H31.0591V14.8974H28.9379ZM26.4947 12.4542V10.333H33.5023V12.4542H26.4947Z"
            fill="#150C09"
          />
          <path
            d="M13.1052 29.8185V31.7882H8.55975V29.8185H13.1052Z"
            fill="#150C09"
          />
          <path
            d="M26.334 34.667V32.7731L29.9515 29.7806C30.1914 29.5818 30.3965 29.3955 30.567 29.2219C30.7406 29.0451 30.8732 28.8636 30.9647 28.6774C31.0594 28.4912 31.1068 28.2844 31.1068 28.0571C31.1068 27.8078 31.0531 27.5947 30.9458 27.4179C30.8416 27.2412 30.6964 27.1054 30.5102 27.0107C30.3239 26.9129 30.1093 26.864 29.8662 26.864C29.6232 26.864 29.4085 26.9129 29.2223 27.0107C29.0392 27.1086 28.8972 27.2522 28.7962 27.4416C28.6952 27.631 28.6446 27.8614 28.6446 28.1329H26.1446C26.1446 27.4511 26.2977 26.864 26.6039 26.3715C26.9101 25.8791 27.3426 25.5003 27.9013 25.2352C28.46 24.97 29.115 24.8374 29.8662 24.8374C30.6428 24.8374 31.3151 24.9621 31.8833 25.2115C32.4546 25.4577 32.895 25.8049 33.2043 26.2532C33.5168 26.7014 33.6731 27.227 33.6731 27.8299C33.6731 28.2023 33.5957 28.5732 33.4411 28.9426C33.2864 29.3087 33.0086 29.7143 32.6077 30.1594C32.2068 30.6045 31.6371 31.1348 30.8984 31.7503L29.9893 32.5079V32.5647H33.7772V34.667H26.334Z"
            fill="#150C09"
          />
        </g>
        <rect
          x="1.25"
          y="1.25"
          width="37.5"
          height="37.5"
          stroke="black"
          strokeWidth="2.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_13464_14816"
          x="0"
          y="0"
          width="42.5"
          height="42.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.5" dy="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_13464_14816"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_13464_14816"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_13464_14816">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
