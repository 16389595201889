import { useEffect, useRef, useState } from "react";
import {
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useOutletContext,
} from "react-router-dom";
import Countdown from "react-countdown";
import {
  HeyGGFooter,
  HeyGGAppShell,
  useCheckAuth,
  useSendStats,
  useLogStats,
  useStreak,
} from "@heygoodgame/common";

import "@bcgov/bc-sans/css/BCSans.css";
import { User } from "@heygoodgame/common/dist/hooks/useAuthenticate";

import { WordOfDayResponse, getWordOfDay, randomWordOfDay } from "../lib/words";
import { isValidLevel, Level } from "../utils/isValidLevel";
import { SettingsModal } from "../components/modals/SettingsModal";
import { InfoModal } from "../components/modals/InfoModal";
import Toggle, { PRACTICE } from "../components/toggle";
import HowToPlay from "../components/howToPlay";
import useGameMode from "../hooks/useGameMode";
import Banner from "../components/banner";
import SEO from "../components/seo";
import useGoogleAnalyticsPageTracking from "../hooks/useGoogleAnalyticsPageTracking";
import useDarkMode from "../hooks/useDarkMode";
import NavbarLink from "../components/NavbarLink";
import Logo from "../components/logo";
import BurgerRive from "../components/burgerRive";
import MathlerIcon from "../components/MathlerIcon";
import OnBoardingModal from "../components/modals/OnBoardingModal";
import formatLocalDate from "../utils/formatLocalDate";

export const ALERT_TIME_MS = 2000;
const colorblindmode = localStorage.getItem("colorblindmode");
const darkmode = localStorage.getItem("darkmode");

if (colorblindmode === "colorblind") {
  document.body.classList.add("colorblind");
}

if (darkmode === "darkmode") {
  document.body.classList.add("darkmode");
}

type ContextType = {
  level: string;
  solution: string;
  solutionIndex: number;
  solutionMath: number;
  tomorrow: number;
  isPractice: boolean;
  isStatsModalOpen: boolean;
  setIsStatsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isGameWon: boolean;
  setIsGameWon: React.Dispatch<React.SetStateAction<boolean>>;
  isGameLost: boolean;
  setIsGameLost: React.Dispatch<React.SetStateAction<boolean>>;
  isWinModalOpen: boolean;
  setIsWinModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: User | null;
  userLoading: boolean;
};

type LoaderResponse = WordOfDayResponse & { isPractice: boolean };

export function loader({
  params,
  request,
}: LoaderFunctionArgs): LoaderResponse {
  const url = new URL(request.url);
  const isPractice = url.searchParams.get(PRACTICE);
  const level = isValidLevel(params.level) ? params.level : "normal";

  if (isPractice === "true") {
    const practiceLevel = localStorage.getItem(`practiceLevel-${level}`);
    if (practiceLevel) {
      return JSON.parse(practiceLevel);
    } else {
      const payload = { ...randomWordOfDay(level), isPractice: true };
      localStorage.setItem(`practiceLevel-${level}`, JSON.stringify(payload));
      return payload;
    }
  } else {
    return { ...getWordOfDay(level), isPractice: false };
  }
}

function Root() {
  const { level, solution, solutionIndex, solutionMath, tomorrow, isPractice } =
    useLoaderData() as LoaderResponse;

  const apiBaseUrl =
    process.env.REACT_APP_API_BASE_URL || "https://play.hey.gg";
  const GAME_KEY = "mathler";

  console.log("useCheckAuth with apiBaseUrl", apiBaseUrl);
  const { user, anonymousId, loading } = useCheckAuth({
    apiBaseUrl,
  });

  const [isGameWon, setIsGameWon] = useGameMode(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useGameMode(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useGameMode(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useGameMode(false);
  const [isWinModalOpen, setIsWinModalOpen] = useGameMode(false);
  const [isGameLost, setIsGameLost] = useGameMode(false);
  const [isSidebarOpen, setIsSidebarOpen] = useGameMode(false);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);
  const sinceDate = formatLocalDate(sevenDaysAgo);

  const { logs } = useLogStats({
    apiBaseUrl,
    gameKey: GAME_KEY,
    option: level.toLowerCase(),
    since: sinceDate,
    deps: [isGameWon, isGameLost],
  });

  const { streaks } = useStreak({
    apiBaseUrl,
    gameKey: GAME_KEY,
    level,
    deps: [isGameWon, isGameLost],
  });

  const { sendHistoricalStats } = useSendStats(
    process.env.REACT_APP_API_BASE_URL || "https://play.hey.gg"
  );

  const [hasSyncedStats, setHasSyncedStats] = useState(false);

  const transformLevel = (level: string): string => {
    if (level.endsWith("-practice")) {
      const baseLevelName = level.replace("-practice", "");
      return `practice:${baseLevelName}`;
    }
    return level;
  };

  useEffect(() => {
    if (!user) return;

    const modes = [
      {
        key: "colorblindmode",
        truly: "colorblind",
        falsy: "nocolorblind",
        userSetting: user.color_blind_mode,
      },
      {
        key: "darkmode",
        truly: "darkmode",
        falsy: "nodarkmode",
        userSetting: user.dark_mode,
      },
    ];

    let reloadPage = false;

    for (const { key, truly, falsy, userSetting } of modes) {
      const currentMode = localStorage.getItem(key);

      if (
        currentMode === null ||
        (currentMode === truly && !userSetting) ||
        (currentMode === falsy && userSetting)
      ) {
        localStorage.setItem(key, userSetting ? truly : falsy);
        reloadPage = true;
      }
    }

    if (reloadPage) {
      window.location.reload();
    }
  }, [user]);

  useEffect(() => {
    const syncStats = async () => {
      console.log("Checking if we need to sync historical stats...");

      console.log("user", user);
      console.log("anonymousId", anonymousId);
      console.log("hasSyncedStats", hasSyncedStats);

      if ((user || anonymousId) && !hasSyncedStats) {
        const levels = [
          "easy",
          "normal",
          "hard",
          "killer",
          "easy-practice",
          "normal-practice",
          "hard-practice",
          "killer-practice",
        ];

        for (const level of levels) {
          console.log(`Checking ${level}...`);

          const transformedLevel = transformLevel(level);
          const syncKey = `mathler-historical-sync-${level}`;
          const key = `gameStats-${level}`;

          if (!localStorage.getItem(syncKey)) {
            const statsString = localStorage.getItem(key);
            if (statsString) {
              console.log(`Syncing stats for ${transformedLevel}...`);

              try {
                const stats = JSON.parse(statsString);
                await sendHistoricalStats({
                  gameKey: GAME_KEY,
                  level: transformedLevel,
                  stats,
                });
              } catch (error) {
                console.error(
                  `Failed to sync stats for ${transformedLevel}:`,
                  error
                );
              }
            }
            localStorage.setItem(syncKey, "true");
          }
        }
        setHasSyncedStats(true);
      }
    };

    syncStats();
  }, [user, anonymousId, sendHistoricalStats, hasSyncedStats]);

  useGoogleAnalyticsPageTracking();

  const isDarkMode = useDarkMode();

  (window as any).mathlerTomorrow = tomorrow;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside as any);

    return () =>
      document.removeEventListener("mousedown", handleClickOutside as any);
  }, [setIsSidebarOpen]);

  return (
    <div className="heygg-body" style={{ overflowX: "hidden" }}>
      <SEO
        title={`A free daily math puzzle game ${
          level ? `(${level} level)` : ""
        }`}
        description={
          "Play free daily Mathler games online from Easy to Killer level on mathler.com. Inspired by Wordle, test your math skills with this math based web game. "
        }
      />

      <HeyGGAppShell
        user={user}
        logo={<Logo />}
        burger={<BurgerRive open={isSidebarOpen} />}
        open={isSidebarOpen}
        gameIcon={<MathlerIcon />}
        gameTitle="Mathler"
        setOpen={setIsSidebarOpen}
        ref={sidebarRef as any}
        logs={logs}
        streaks={streaks}
        statsCallback={() => setIsStatsModalOpen(true)}
        settingCallback={() => setIsSettingsModalOpen(true)}
        faqCallback={() => setIsInfoModalOpen(true)}
        leaderboardCallback={() =>
          (window.location.href = `https://play.hey.gg/dashboard/leaderboard/mathler?return_to=${window.location.href}`)
        }
        gameCommunityCallback={() =>
          window.open(
            `https://play.hey.gg/auth/discord?return_to=${window.location.href}`
          )
        }
        accountSettingCallback={() => {
          window.open(
            `https://play.hey.gg/profile?return_to=${window.location.href}`
          );
        }}
        accountStatsCallback={() => {
          window.open(
            `https://play.hey.gg/dashboard/mathler/${isPractice ? "practice:" : ""}${level}?return_to=${window.location.href}`
          );
        }}
      />

      <div className="sticky-pushdown">
        <div id="mathler_M_1"></div>
      </div>

      <div
        className="flex justify-around md:py-8 mx-auto"
        style={{ ...(!isDarkMode ? { backgroundColor: "#F5F7FA" } : {}) }}
      >
        <div className="sticky-siderail-left">
          <div className="inner">
            <div id="mathler_D_1"></div>
          </div>
        </div>
        <div className="mx-2 my-6 text-center flex justify-center items-center flex-col max-w-full overflow-hidden">
          <div className="mx-auto items-center">
            <div className="flex w-90 mx-auto items-center mb-2">
              <h2 className="text-lg grow text-center">
                Find the hidden calculation{" "}
                <span className="bg-[#FCEB4E] no-darkmode rounded px-0.5">
                  that equals {solutionMath}
                </span>
              </h2>
            </div>
          </div>

          <div className="flex w-full w-[361px] mx-auto items-center justify-between mb-1">
            <NavbarLink />
            <div className="flex">
              <Toggle checked={isPractice} />
              <label className="toggle-label">Practice</label>
            </div>
          </div>

          {(isGameLost || (isGameWon && !isPractice)) && (
            <div className="flex w-90 mx-auto items-center mb-2">
              <h2 className="text-md grow text-center">
                New puzzle in <Countdown date={tomorrow} daysInHours={true} />
              </h2>
            </div>
          )}
          <Outlet
            context={{
              level,
              solution,
              solutionIndex,
              solutionMath,
              tomorrow,
              isPractice,
              isStatsModalOpen,
              setIsStatsModalOpen,
              isGameWon,
              setIsGameWon,
              isGameLost,
              setIsGameLost,
              isWinModalOpen,
              setIsWinModalOpen,
              user,
              userLoading: loading,
            }}
          />
        </div>
        <div className="sticky-siderail-right">
          <div className="inner">
            <div id="mathler_D_2"></div>
          </div>
        </div>

        <InfoModal
          isOpen={isInfoModalOpen}
          setIsOpen={setIsInfoModalOpen}
          level={level as Level}
        />
        <SettingsModal
          isOpen={isSettingsModalOpen}
          handleClose={() => setIsSettingsModalOpen(false)}
        />

        <OnBoardingModal isUserLoggedIn={!!user} />
      </div>
      <div className="below-game-footer-mobile">
        <div className="inner">
          <div id="mathler_M_2"></div>
        </div>
      </div>

      <Banner />

      <div className="w-90 mx-auto items-center feedback">
        <a href="/feedback.html" target="_blank">
          Have feedback?
        </a>
      </div>

      <HowToPlay />

      <div className="incontent-bottom">{/* No ads to be display here */}</div>

      <div className="heygg-body">
        <HeyGGFooter copyRightStartDate={"2022"} />
      </div>
    </div>
  );
}

export function useGameState() {
  return useOutletContext<ContextType>();
}

export default Root;
