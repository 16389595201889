import { Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import XIcon from "../xIcon/XIcon";

type Props = {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  isNew?: boolean;
};

export const BaseModal = ({
  title,
  children,
  isOpen,
  handleClose,
  isNew,
}: Props) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div
          className="flex items-center justify-center min-h-screen py-10 px-4 text-center sm:block sm:p-0
        bg-gray-500 bg-opacity-75 transition-opacity"
        >
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 shadow-custom-black">
              <div className="absolute right-4 top-6">
                <XIcon onClick={() => handleClose()} />
              </div>
              <div>
                {isNew && (
                  <div
                    className="flex justify-center items-center gap-2 px-2 py-1 rounded-full bg-[#57E197] mx-auto mb-3 w-auto"
                    style={{ width: "fit-content" }}
                  >
                    <div className="flex items-center text-[#150C09] text-center font-nunito text-sm font-bold">
                      NEW
                    </div>
                  </div>
                )}

                <DialogTitle
                  as="h3"
                  className="text-center font-nunito text-2xl font-extrabold leading-normal"
                >
                  {title}
                </DialogTitle>
                <div className="mt-2">{children}</div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};
