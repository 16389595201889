import { Cell } from "../grid/Cell";
import { BaseModal } from "./BaseModal";
import { Level } from "../../utils/isValidLevel";

export const SimpleInfoModal = ({
  level,
  isOpen,
  setIsOpen,
}: {
  level: Level;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // DO NOT SHOW THIS MODAL, INSTEAD SHOW THE NEW UPDATE MODAL ABOUT ACCOUNTS

  // useEffectAfterMigration(() => {
  //   const hasPlayedBefore = localStorage.getItem("playedbefore");

  //   if (hasPlayedBefore) return;

  //   if (level === "normal") {
  //     setIsOpen(true);
  //     localStorage.setItem(updateKey, "true"); // We do not need to see the updated modal
  //     localStorage.setItem("playedbefore", "played");
  //   }
  // }, [level, setIsOpen]);

  function handleCloseClick() {
    setIsOpen(false);
  }

  return (
    <BaseModal
      title="How to Play Mathler"
      isOpen={isOpen}
      handleClose={handleCloseClick}
    >
      <div className="flex flex-col text-base font-normal gap-y-4">
        <p>Try to find the hidden calculation in 6 guesses!</p>
        <p>
          After each guess, the color of the tiles will change to show how close
          you are to the solution.
        </p>

        <div className="flex justify-center">
          <Cell value="5" status="correct" />
          <Cell value="0" status="absent" />
          <Cell value="-" status="correct" />
          <Cell value="2" status="present" />
          <Cell value="5" status="absent" />
        </div>

        <ul className="list-disc pl-4">
          <li>Green are in the correct place.</li>
          <li>Orange are in the solution, but in a different place.</li>
          <li>Gray are not in the solution.</li>
          <li>Calculate / or * before - or + (order of operations).</li>
        </ul>

        <button
          type="button"
          className="flex p-4 justify-center items-center gap-2 self-stretch bg-black text-white rounded-md cursor-pointer"
          onClick={handleCloseClick}
          onTouchStart={handleCloseClick}
        >
          Play Mathler Now
        </button>
      </div>
    </BaseModal>
  );
};
