import { useState, useEffect } from "react";
import { useSendStats } from "@heygoodgame/common";

import "@bcgov/bc-sans/css/BCSans.css";

import { WIN_MESSAGES } from "../constants/strings";
import { GAME_EPOCH, MS_IN_DAY } from "../constants/dates";
import { addStatsForCompletedGame, loadStats } from "../lib/stats";
import {
  checkExpressionWithSolution,
  isWinningWordExact,
  isWordInWordList,
} from "../lib/words";
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from "../lib/localStorage";
import { Level } from "../utils/isValidLevel";

import { ALERT_TIME_MS, useGameState } from "./root";
import { Alert } from "../components/alerts/Alert";
import { Grid } from "../components/grid/Grid";
import { Keyboard } from "../components/keyboard/Keyboard";
import { CONFIG } from "../constants/config";
import { StatsModal } from "../components/modals/StatsModal";
import useGameMode from "../hooks/useGameMode";
import WinModal from "../components/modals/WinModal";
import { SimpleInfoModal } from "../components/modals/SimpleInfoModal";
import GameCompletionAction from "../components/buttons/GameCompletionAction";
import { collectGoogleAnalyticsPageView } from "../hooks/useGoogleAnalyticsPageTracking";
import RestartPracticeGame from "../components/buttons/RestartPracticeGame";
import ModalWithSlice from "../components/modals/ModalWithSlice";
import formatLocalDate from '../utils/formatLocalDate';

function Levels() {
  const {
    level,
    solution,
    solutionMath,
    solutionIndex,
    tomorrow,
    isPractice,
    isStatsModalOpen,
    setIsStatsModalOpen,
    isGameWon,
    setIsGameWon,
    isGameLost,
    setIsGameLost,
    isWinModalOpen,
    setIsWinModalOpen,
    user,
    userLoading,
  } = useGameState();

  const [currentGuess, setCurrentGuess] = useGameMode<Array<string>>([]);
  const [isGameWonExact, setIsGameWonExact] = useGameMode(false);
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useGameMode(false);
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] =
    useGameMode(false);
  const [successAlert, setSuccessAlert] = useGameMode("");
  const [isSimpleInfoModalOpen, setIsSimpleInfoModalOpen] = useGameMode(false);
  const { sendStats } = useSendStats(
    process.env.REACT_APP_API_BASE_URL || "https://play.hey.gg",
  );

  const [guesses, setGuesses] = useGameMode<string[][]>(() => {
    const loaded = loadGameStateFromLocalStorage(level as Level, isPractice);

    if (loaded?.solution !== solution) {
      return [];
    }

    const gameWasWon = loaded.guesses
      .map((guess) => checkExpressionWithSolution(guess.join(""), solution))
      .includes(true);

    const gameWasWonExact = loaded.guesses
      .map((guess) => guess.join(""))
      .includes(solution);

    if (gameWasWon) {
      setIsGameWon(true);
    }

    if (gameWasWonExact) {
      setIsGameWonExact(true);
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true);
    }

    return loaded.guesses;
  }, []);

  (window as any).mathlerTomorrow = tomorrow;

  const [stats, setStats] = useState(() =>
    loadStats(level as Level, isPractice),
  );

  useEffect(() => {
    setStats(loadStats(level as Level, isPractice));
    setGuesses(() => {
      const loaded = loadGameStateFromLocalStorage(level as Level, isPractice);
      if (loaded?.solution !== solution) {
        return [];
      }

      return loaded?.guesses;
    });
  }, [isPractice, level, setGuesses, solution]);

  useEffect(() => {
    if (isGameWonExact) {
      setSuccessAlert(
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)],
      );
      setTimeout(() => {
        setSuccessAlert("");
        setIsWinModalOpen(true);
      }, ALERT_TIME_MS);
    }

    if (isGameWon && !isGameWonExact) {
      setSuccessAlert(`Well done! Commutative solution accepted.`);
      setTimeout(() => {
        setSuccessAlert("");
        setIsWinModalOpen(true);
      }, 3000);
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsWinModalOpen(true);
      }, ALERT_TIME_MS);
    }
  }, [
    isGameWon,
    isGameWonExact,
    isGameLost,
    isPractice,
    setSuccessAlert,
    setIsWinModalOpen,
  ]);

  const onChar = (value: string) => {
    if (
      currentGuess.length < solution.length &&
      guesses.length < CONFIG.tries &&
      !isGameWon
    ) {
      let newGuess = currentGuess.concat([value]);
      setCurrentGuess(newGuess);
    }
  };

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1));
  };

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return;
    }

    if (!(currentGuess.length === solution.length)) {
      setIsNotEnoughLetters(true);
      return setTimeout(() => {
        setIsNotEnoughLetters(false);
      }, ALERT_TIME_MS);
    }

    if (!isWordInWordList(currentGuess.join(""), solutionMath)) {
      setIsWordNotFoundAlertOpen(true);
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false);
      }, ALERT_TIME_MS);
    }

    collectGoogleAnalyticsPageView();

    const winningWord = checkExpressionWithSolution(
      currentGuess.join(""),
      solution,
    );
    const winningWordExact = isWinningWordExact(
      currentGuess.join(""),
      solution,
    );

    if (
      currentGuess.length === solution.length &&
      guesses.length < CONFIG.tries &&
      !isGameWon
    ) {
      saveGameStateToLocalStorage(
        { guesses: [...guesses, currentGuess], solution },
        level as Level,
        isPractice,
      );

      setGuesses([...guesses, currentGuess]);
      setCurrentGuess([]);

      if (winningWord) {
        postStats(
          true,
          level as Level,
          guesses.length,
          isPractice,
          solutionIndex,
          sendStats,
        );
      }

      if (winningWordExact) {
        setStats(
          addStatsForCompletedGame(
            stats,
            guesses.length,
            level as Level,
            isPractice,
          ),
        );
        setIsGameWonExact(true);
        setIsGameWon(true);
        return;
      }

      if (winningWord && !winningWordExact) {
        setStats(
          addStatsForCompletedGame(
            stats,
            guesses.length,
            level as Level,
            isPractice,
          ),
        );
        return setIsGameWon(true);
      }

      if (guesses.length === CONFIG.tries - 1) {
        postStats(
          false,
          level as Level,
          guesses.length,
          isPractice,
          solutionIndex,
          sendStats,
        );
        setIsGameLost(true);
      }
    }
  };

  return (
    <>
      <Grid
        guesses={guesses}
        currentGuess={currentGuess}
        solution={solution}
        level={level as Level}
      />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        solution={solution}
        level={level as Level}
      />
      <GameCompletionAction
        guesses={guesses}
        handleShare={() => {
          setSuccessAlert("Game copied to clipboard");
          return setTimeout(() => setSuccessAlert(""), ALERT_TIME_MS);
        }}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        isPracticeGame={isPractice}
        level={level as Level}
        solution={solution}
        solutionIndex={solutionIndex}
      />
      <RestartPracticeGame
        level={level as Level}
        isPracticeGame={isPractice}
        isGameWon={isGameWon}
        isGameLost={isGameLost}
      />

      <Alert message="Not enough numbers" isOpen={isNotEnoughLetters} />
      <Alert
        message={`Every guess must equal ${solutionMath} ... are you forgetting order of operations?`}
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert message={`The solution was ${solution}`} isOpen={isGameLost} />
      <Alert
        message={successAlert}
        isOpen={successAlert !== ""}
        variant="success"
      />
      <WinModal
        level={level as Level}
        solution={solution}
        isOpen={isWinModalOpen}
        handleClose={() => setIsWinModalOpen(false)}
        gameStats={stats}
        isGameWon={isGameWon}
        isPracticeGame={isPractice}
        handleShare={() => {
          setSuccessAlert("Game copied to clipboard");
          return setTimeout(() => setSuccessAlert(""), ALERT_TIME_MS);
        }}
        solutionIndex={solutionIndex}
        guesses={guesses}
        isGameLost={isGameLost}
        isLogin={!!user}
      />
      <StatsModal
        gameStats={stats}
        handleClose={() => setIsStatsModalOpen(false)}
        isGameWon={isGameWon}
        isOpen={isStatsModalOpen}
        guesses={guesses}
      />
      <SimpleInfoModal
        level={level as Level}
        isOpen={isSimpleInfoModalOpen}
        setIsOpen={setIsSimpleInfoModalOpen}
      />
      <ModalWithSlice isUserLoggedIn={!!user} userLoading={userLoading} />
    </>
  );
}

function getDateFromEpoch(timestamp: number): string {
  const daysSinceEpoch = Math.floor((timestamp - GAME_EPOCH) / MS_IN_DAY);

  // Calculate the date for the current puzzle using the same epoch reference
  const puzzleDate = new Date(GAME_EPOCH + daysSinceEpoch * MS_IN_DAY);

  // Format as YYYY-MM-DD
  return formatLocalDate(puzzleDate);
}

function postStats(
  isGameWon: boolean,
  level: string,
  guessCount: number,
  isPractice: boolean,
  solutionIndex: number,
  sendStats: (data: any) => Promise<void>,
) {
  const gameDate = getDateFromEpoch(Date.now());
  const statsLevel = isPractice ? "practice:" + level : level;
  const gameId = `${statsLevel}:${solutionIndex}`;
  const won = isGameWon ? "win" : "loss";

  sendStats({
    gameKey: "mathler",
    gameDate,
    gameId,
    gameResult: won,
    stats: {
      level: statsLevel,
      guesses: guessCount + 1,
      won,
    },
  })
    .then(() => {
      console.log("Stats sent successfully");
    })
    .catch((err) => {
      console.error("Failed to send stats:", err);
    });
}

export default Levels;
